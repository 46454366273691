import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { PlaybookListItem } from '@legalfly/components/playbooks/PlaybookListItem';
import { SelectPlaybook } from '@legalfly/components/playbooks/SelectPlaybook';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { TextareaLineNumbers } from '@legalfly/ui/textarea';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { usePlaybooks } from 'core/modules/playbooks';

interface Props {
  children: (props: { playbooks: ApiPlaybook[]; virtualPlaybookItems: string }) => ReactNode;
}

export const MultiReviewSelectPlaybooks = ({ children }: Props) => {
  const { t } = useTranslation();

  const { playbooks } = usePlaybooks();
  const [activePlaybooks, setActivePlaybooks] = useState<ApiPlaybook[]>([]);

  const [virtualPlaybookItems, setVirtualPlaybookItems] = useState<string>('');

  return (
    <div className='flex-1'>
      <Content>
        <ContentHeader>
          <ContentHeader.Title variant='heading'>
            {t('multiReview.playbook.title')}
          </ContentHeader.Title>
          <ContentHeader.SubTitle variant='bodyLight'>
            {t('multiReview.playbook.subtitle')}
          </ContentHeader.SubTitle>
        </ContentHeader>
        <SelectPlaybook
          playbooks={playbooks}
          triggerProps={{ size: 'md', className: 'mb-5' }}
          selectedPlaybooks={activePlaybooks}
          onChange={(playbook) => {
            trackEvent({
              action: 'click',
              category: 'multiReviewStart',
              label: 'addPlaybook',
            });
            setActivePlaybooks([...activePlaybooks, playbook]);
          }}
        />
        <div className='flex flex-col gap-3'>
          {activePlaybooks.map((playbook) => {
            const isChecked = activePlaybooks.some((p) => p.uuid === playbook.uuid);

            return (
              <PlaybookListItem
                key={playbook.uuid}
                playbook={playbook}
                checked={isChecked}
                sheetTopOffset='calc(var(--lf-content-header-height) + var(--lf-body-padding) * 2)'
                onCheckedChange={(checked) => {
                  trackEvent(
                    {
                      action: 'click',
                      category: 'multiReviewStart',
                      label: checked ? 'selectPlaybook' : 'deselectPlaybook',
                    },
                    { status: playbook.status },
                  );

                  const updatedActiveIds = checked
                    ? [...activePlaybooks, playbook]
                    : activePlaybooks.filter((p) => p.uuid !== playbook.uuid);

                  setActivePlaybooks(updatedActiveIds);
                }}
                onRemove={() => {
                  trackEvent({
                    action: 'click',
                    category: 'multiReviewStart',
                    label: 'removePlaybook',
                  });

                  setActivePlaybooks(activePlaybooks.filter((p) => p.uuid !== playbook.uuid));
                }}
              />
            );
          })}
        </div>
        <Divider variant='weak' className='my-5' />
        <div className='mb-5'>
          <Text as='h1' variant='heading' className='mb-2'>
            {t('multiReview.playbook.oneTime')}
          </Text>
          <Text as='h2' variant='bodyLight' className='mb-5 text-content-body-placeholder'>
            {t('multiReview.playbook.oneTimeDescription')}
          </Text>
          <TextareaLineNumbers
            placeholder={t('playbooks.bulk.placeholder')}
            value={virtualPlaybookItems}
            rows={20}
            onChange={(e) => setVirtualPlaybookItems(e.target.value)}
          />
        </div>
        {children({ playbooks: activePlaybooks, virtualPlaybookItems })}
      </Content>
    </div>
  );
};
