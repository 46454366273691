const supportedLanguages = ['en', 'nl', 'de', 'fr'] as const;
const environment = ['local', 'development', 'production', 'staging'] as const;

export const legalFlyConfig = {
  supportedLanguages,
  defaultLanguage: supportedLanguages[0],
  environment,
  api: {
    url: import.meta.env?.VITE_API_URL || 'http://localhost',
    port: parseInt(import.meta.env?.VITE_API_PORT || '3000', 10),
    paths: {
      admin: {
        users: {
          current: '/admin/users/current',
        },
        tenants: {
          list: '/admin/tenants',
          organizations: {
            create: '/admin/tenants/:tenantUuid/organizations',
            list: '/admin/tenants/:tenantUuid/organizations',
            update: '/admin/tenants/:tenantUuid/organizations/:organizationUuid',
            users: {
              create: '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users',
              list: '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users',
              update: '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users/:userUuid',
              deactivate:
                '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users/:userUuid/deactivate',
              activate:
                '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users/:userUuid/activate',
              delete: '/admin/tenants/:tenantUuid/organizations/:organizationUuid/users/:userUuid',
            },
          },
        },
      },
      anonymization: {
        list: '/anonymizations',
        create: '/anonymizations',
        detail: '/anonymizations/:uuid',
        update: '/anonymizations/:uuid',
        delete: '/anonymizations/:uuid',
      },
      feedback: {
        send: '/feedback',
      },
      conversations: {
        list: '/conversations',
        create: '/conversations',
        message: {
          create: '/conversations/message',
          stop: '/conversations/:uuid/message/:messageUuid/stop',
        },
        detail: '/conversations/:uuid',
        update: '/conversations/:uuid',
        delete: '/conversations/:uuid',
        clear: '/conversations/:uuid/clear',
      },
      documents: {
        folder: {
          detail: '/documents/folder/:uuid?',
          create: '/documents/folder/:uuid?',
          delete: '/documents/folder/:uuid?',
          drop: '/documents/folder/drop',
          update: '/documents/folder/:uuid?',
          count: '/documents/folder/count/:uuids',
        },
        files: {
          retry: '/documents/file/:uuid/retry',
          create: '/documents/file/:uuid?',
          detail: '/documents/file/:uuid',
          download: {
            original: '/documents/file/:uuid/download',
            anonymized: '/documents/file/:uuid/download/anonymized',
          },
          snippets: '/documents/file/:uuid/snippets',
          summary: '/documents/file/:uuid/summary',
          entities: {
            create: '/documents/file/:uuid/entities',
            update: '/documents/file/:uuid/entities/:entityUuid',
            delete: '/documents/file/:uuid/entities/:entityUuid',
          },
          update: '/documents/file/:uuid',
          delete: '/documents/file/:uuid',
        },
        delete: '/documents',
        download: {
          zip: {
            original: '/documents/download/zip',
            anonymized: '/documents/download/zip/anonymized',
          },
        },
      },
      playbooks: {
        system: {
          list: '/playbooks/system/:category',
        },
        list: '/playbooks',
        detail: '/playbooks/:playbookUuid',
        create: '/playbooks',
        delete: '/playbooks/:playbookUuid',
        update: '/playbooks/:playbookUuid',
        items: {
          create: '/playbooks/:playbookUuid/items',
          move: '/playbooks/:playbookUuid/items/:itemUuid/move',
          update: '/playbooks/:playbookUuid/items/:itemUuid',
          delete: '/playbooks/:playbookUuid/items/:itemUuid',
        },
      },
      reviews: {
        list: '/reviews',
        create: '/reviews',
        detail: '/reviews/:uuid',
        delete: '/reviews/:uuid',
      },
      statistics: {
        list: '/statistics',
      },
      multiReviews: {
        list: '/multi-reviews',
        create: '/multi-reviews',
        detail: '/multi-reviews/:uuid',
        delete: '/multi-reviews/:uuid',
        update: '/multi-reviews/:uuid',
      },
      users: {
        update: '/users/:uuid',
        detail: '/users/:uuid',
        delete: '/users/:uuid',
        deactivate: '/users/:uuid/deactivate',
        activate: '/users/:uuid/activate',
        create: '/users',
        list: '/users',
        current: '/users/current',
        updateAvatar: '/users/avatar',
      },
      notifications: {
        list: '/notifications',
        create: '/notifications',
        delete: '/notifications/:uuid',
        deleteAll: '/notifications',
        markAllAsSeen: '/notifications/mark-all-as-seen',
        markAsSeen: '/notifications/:uuid/mark-as-seen',
      },
      drafting: {
        templates: {
          list: '/templates',
          detail: '/templates/:uuid',
          create: '/templates',
          delete: '/templates/:uuid',
          update: '/templates/:uuid',
          clauses: {
            create: '/templates/:uuid/clauses',
            move: '/templates/:uuid/clauses/:clauseUuid/move',
            update: '/templates/:uuid/clauses/:clauseUuid',
            delete: '/templates/:uuid/clauses/:clauseUuid',
          },
        },
        drafts: {
          list: '/drafts',
          detail: '/drafts/:uuid',
          create: '/drafts',
          delete: '/drafts/:uuid',
          update: '/drafts/:uuid',
          download: {
            pdf: '/drafts/:uuid/pdf',
            docx: '/drafts/:uuid/docx',
          },
          clauses: {
            create: '/drafts/:uuid/clauses',
            move: '/drafts/:uuid/clauses/:clauseUuid/move',
            update: '/drafts/:uuid/clauses/:clauseUuid',
            delete: '/drafts/:uuid/clauses/:clauseUuid',
          },
        },
      },
      settings: {
        user: '/settings/user',
        organization: '/settings/organization',
      },
      seed: '/seed',
      config: {
        list: '/config',
      },
      websocket: {
        authenticate: '/ws/authenticate',
        polling: '/ws/polling',
        token: '/:token',
      },
      agents: {
        list: '/agents',
        get: '/agents/:uuid',
        conversations: '/agents/:uuid/conversations',
        create: '/agents',
        update: '/agents/:uuid',
        delete: '/agents/:uuid',
      },
      favorites: {
        toggle: '/favorites/toggle',
      },
      office: {
        word: {
          document: {
            get: '/office/word/document/:uuid',
            create: '/office/word/document',
          },
        },
      },
    },
  },
  websocket: {
    hostname: new URL(import.meta.env?.VITE_API_URL || 'http://localhost').hostname,
    port: parseInt(import.meta.env?.VITE_WS_PORT || '3000', 10),
    protocol: import.meta.env?.VITE_WS_PROTOCOL || 'ws',
  },
} as const;

export type LegalFlyConfig = typeof legalFlyConfig;
export type LegalFlyLanguage = (typeof supportedLanguages)[number];
export type LegalFlyEnvironment = (typeof environment)[number];

export const LegalFlyLanguageEnum = supportedLanguages.reduce(
  (acc, lang) => {
    acc[lang.toUpperCase()] = lang;
    return acc;
  },
  {} as Record<string, LegalFlyLanguage>,
);
