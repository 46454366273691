import {
    BaseEntity,
    ChildEntity,
    Column,
    CreateDateColumn,
    Entity,
    Generated,
    Index,
    PrimaryGeneratedColumn,
    TableInheritance,
    UpdateDateColumn,
} from 'typeorm';

import type {
    AgentPermissions,
    DiscoveryPermissions,
    DraftPermissions,
    MultiReviewPermissions,
    PermissionEntityType,
    PermissionOwnerType,
    ReviewPermissions,
    TemplatePermissions,
    UniquePermissions,
} from '@legalfly/api/core';
import { permissionConfig, permissionEntityTypes, uniquePermissions } from '@legalfly/api/core';

@Index(['ownerType', 'ownerId'])
@Index(['type', 'typeId'])
@TableInheritance({ column: { type: 'enum', name: 'type', enum: permissionEntityTypes } })
@Entity()
export class Permission extends BaseEntity
{
    @PrimaryGeneratedColumn({ type: 'integer' })
    id: number;

    @Column({
        type: 'uuid',
        unique: true,
    })
    @Generated('uuid')
    uuid: string;

    @Column({
        type: 'enum',
        enum: permissionEntityTypes,
    })
    type: PermissionEntityType;

    @Column({
        type: 'integer',
    })
    typeId: number;

    @Column({
        type: 'enum',
        enum: uniquePermissions,
    })
    action: UniquePermissions;

    @Column({
        type: 'enum',
        enum: permissionConfig.owners,
    })
    ownerType: PermissionOwnerType;

    @Column({
        type: 'integer',
    })
    ownerId: number;

    @CreateDateColumn()
    createdAt: Date;

    @UpdateDateColumn()
    updatedAt: Date;
}

@PermissionChildEntity('agent')
export class PermissionTypeAgent extends Permission
{
    declare type: 'agent';
    declare action: AgentPermissions;
}

@PermissionChildEntity('discovery')
export class PermissionTypeDiscovery extends Permission
{
    declare type: 'discovery';
    declare action: DiscoveryPermissions;
}

@PermissionChildEntity('draft')
export class PermissionTypeDraft extends Permission
{
    declare type: 'draft';
    declare action: DraftPermissions;
}

@PermissionChildEntity('multiReview')
export class PermissionTypeMultiReview extends Permission
{
    declare type: 'multiReview';
    declare action: MultiReviewPermissions;
}

@PermissionChildEntity('review')
export class PermissionTypeReview extends Permission
{
    declare type: 'review';
    declare action: ReviewPermissions;
}

@PermissionChildEntity('template')
export class PermissionTypeTemplate extends Permission
{
    declare type: 'template';
    declare action: TemplatePermissions;
}

/**
 * This wrapper ensures that the ChildEntity decorator only receives PermissionEntityType values.
 */
function PermissionChildEntity<T extends PermissionEntityType>(type: T)
{
    return function (constructor: Function)
    {
        ChildEntity(type)(constructor);
        constructor.prototype.type = type;
    };
}
