import { type ApiDocument } from '@legalfly/api/documents';
import type { WebSocketDataType } from '@legalfly/websockets';
import { WebSocketManagerInstance } from '@legalfly/websockets';

import { useDownloadAnonymizedDocumentUrlViaWS, useDownloadDocumentUrl } from '..';
import { useDownload } from './useDownload';

export const useDownloadAnonymizedDocument = () => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    showErrorToast,
    performDownload,
  } = useDownload();
  const { getDownloadUrlViaWS } = useDownloadAnonymizedDocumentUrlViaWS();

  const handleDownload = async (
    {
      uuid,
      fileName,
    }: {
      uuid: ApiDocument['uuid'];
      fileName: string;
    },
    callback?: () => void,
  ) => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    const wsEvent = `anonymized_document_url_${uuid}` as const;
    const createDownloadListener = async (
      data: WebSocketDataType<'anonymized_document_url_uuid'>,
    ) => {
      WebSocketManagerInstance.unsubscribe(wsEvent, createDownloadListener);
      await performDownload(data.url, fileName);
      callback?.();
    };

    WebSocketManagerInstance.subscribe(wsEvent, createDownloadListener);

    try {
      setIsDownloading(true);
      setProgress(0);
      await getDownloadUrlViaWS({ uuid });
    } catch {
      showErrorToast();
    } finally {
      setIsDownloading(false);
    }
  };

  return { progress, isDownloading, handleDownload };
};

export const useDownloadDocument = () => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    performDownload,
    showErrorToast,
  } = useDownload();
  const { getDownloadUrl } = useDownloadDocumentUrl();

  const handleDownload = async ({
    uuid,
    fileName,
  }: {
    uuid: ApiDocument['uuid'];
    fileName: string;
  }) => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    try {
      const data = await getDownloadUrl({ uuid });
      if (!data?.url) return;
      await performDownload(data.url, fileName);
    } catch {
      showErrorToast();
    } finally {
      setIsDownloading(false);
    }
  };

  return { progress, isDownloading, handleDownload };
};
