import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type {
  ApiConversation,
  ApiConversationMessage,
  ConversationsApi,
} from '@legalfly/api/conversations';
import { ConversationTypeEnum } from '@legalfly/api/conversations';

import { conversationsQueryOptions } from './conversationsQueryOptions';

export const createConversationsModule = ({
  reviewApi,
  discoveryApi,
  draftApi,
  agentApi,
  officeApi,
}: ConversationsApi) => {
  const createConversationsApi = (type: ConversationTypeEnum) => {
    switch (type) {
      case ConversationTypeEnum.REVIEW:
        return reviewApi;
      case ConversationTypeEnum.DRAFTS:
        return draftApi;
      case ConversationTypeEnum.AGENT:
        return agentApi;
      case ConversationTypeEnum.OFFICE:
        return officeApi;
      default:
        return discoveryApi;
    }
  };

  const queryOptions = conversationsQueryOptions({
    createConversationsApi,
  });

  const useConversations = ({ type }: { type: ConversationTypeEnum }) => {
    const { data, refetch, isLoading, error } = useSuspenseQuery(
      queryOptions.conversations({ type }),
    );

    return {
      conversations: data ?? [],
      refetch,
      isLoading,
      error,
    };
  };

  const useConversation = ({
    type,
    uuid,
  }: {
    type: ConversationTypeEnum;
    uuid: ApiConversation['uuid'] | undefined;
  }) => {
    const { data, refetch, isLoading, error, isSuccess } = useQuery(
      queryOptions.conversation({ type, uuid }),
    );

    return {
      conversation: data,
      refetch,
      isLoading,
      error,
      isSuccess,
    };
  };

  const useCreateConversation = ({ type }: { type: ConversationTypeEnum }) => {
    const queryClient = useQueryClient();
    const conversationsApi = createConversationsApi(type);

    const { isPending, mutateAsync } = useMutation({
      mutationKey: [type, 'conversation', 'create'],
      mutationFn: conversationsApi?.createConversation,
      onSuccess: (conversation) => {
        queryClient.setQueryData(
          queryOptions.conversation({ type, uuid: conversation.uuid }).queryKey,
          conversation,
        );
      },
    });

    return {
      createConversation: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateConversationMessage = ({ type }: { type: ConversationTypeEnum }) => {
    const queryClient = useQueryClient();
    const conversationsApi = createConversationsApi(type);

    const { mutateAsync } = useMutation({
      mutationKey: [type, 'conversation', 'createMessage'],
      mutationFn: conversationsApi?.createConversationMessage,
      onSuccess: (res, { uuid: conversationUuid }) => {
        if (res && 'uuid' in res) {
          // First, set the known data
          queryClient.setQueryData(
            queryOptions.conversation({ type, uuid: res.uuid }).queryKey,
            () => res,
          );

          // Refetch the conversation list
          queryClient.invalidateQueries({
            queryKey: queryOptions.conversations({ type }).queryKey,
          });

          if (!conversationUuid) {
            // Then, invalidate the query to get the latest data including the messages
            queryClient.invalidateQueries({
              queryKey: queryOptions.conversation({ type, uuid: res.uuid }).queryKey,
            });
          }
        }
      },
    });

    return {
      createConversationMessage: mutateAsync,
    };
  };

  const useDeleteConversation = ({ type }: { type: ConversationTypeEnum }) => {
    const queryClient = useQueryClient();
    const conversationsApi = createConversationsApi(type);

    const { mutateAsync, isPending } = useMutation({
      mutationKey: [type, 'conversation', 'delete'],
      mutationFn: conversationsApi?.deleteConversation,
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: queryOptions.conversations({ type }).queryKey,
        });
      },
    });

    return {
      deleteConversation: mutateAsync,
      isLoading: isPending,
    };
  };

  const useStopConversationMessage = ({ type }: { type: ConversationTypeEnum }) => {
    const conversationsApi = createConversationsApi(type);

    const { mutateAsync, isPending } = useMutation({
      mutationKey: [type, 'conversation', 'stopMessage'],
      mutationFn: conversationsApi?.stopConversationMessage,
    });

    return {
      stopConversationMessage: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateConversation = ({ type }: { type: ConversationTypeEnum }) => {
    const queryClient = useQueryClient();
    const conversationsApi = createConversationsApi(type);

    const { mutateAsync, isPending } = useMutation({
      mutationKey: [type, 'conversation', 'update'],
      mutationFn: conversationsApi?.updateConversation,
      onSuccess: (res) => {
        queryClient.setQueryData(
          queryOptions.conversation({ type, uuid: res.uuid }).queryKey,
          () => {
            return res;
          },
        );
      },
    });

    return {
      updateConversation: mutateAsync,
      isLoading: isPending,
    };
  };

  const useClearConversation = ({ type }: { type: ConversationTypeEnum }) => {
    const queryClient = useQueryClient();
    const conversationsApi = createConversationsApi(type);

    const { mutateAsync, isPending } = useMutation({
      mutationKey: [type, 'conversation', 'clear'],
      mutationFn: conversationsApi?.clearConversation,
      onSuccess: (res) => {
        queryClient.setQueryData(
          queryOptions.conversation({ type, uuid: res.uuid }).queryKey,
          () => {
            return res;
          },
        );
      },
    });

    return {
      clearConversation: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateConversationMessage = ({
    conversationType,
  }: {
    conversationType: ConversationTypeEnum;
  }) => {
    const queryClient = useQueryClient();

    return (
      conversationUuid: string,
      messageUuid: string,
      updatedMessage: Partial<ApiConversationMessage>,
    ) => {
      queryClient.setQueryData(
        queryOptions.conversation({ type: conversationType, uuid: conversationUuid }).queryKey,
        (conversation) => {
          if (!conversation) return undefined;

          return {
            ...conversation,
            messages: conversation.messages.map((msg) =>
              msg.uuid === messageUuid
                ? {
                    ...msg,
                    ...updatedMessage,
                  }
                : msg,
            ),
          };
        },
      );
    };
  };

  const useAddConversationMessage = ({
    conversationType,
  }: {
    conversationType: ConversationTypeEnum;
  }) => {
    const queryClient = useQueryClient();

    return (
      conversationUuid: string,
      messageUuid: string,
      question: ApiConversationMessage['question'],
    ) => {
      queryClient.setQueryData(
        queryOptions.conversation({ type: conversationType, uuid: conversationUuid }).queryKey,
        (conversation) => {
          if (!conversation) return undefined;

          return {
            ...conversation,
            messages: [
              ...conversation.messages,
              {
                uuid: messageUuid,
                question,
                assistantMessage: '',
                sources: [],
                createdAt: new Date(),
                status: 'in_progress' as const,
              },
            ],
          };
        },
      );
    };
  };

  return {
    useConversations,
    useConversation,
    useCreateConversationMessage,
    useDeleteConversation,
    useStopConversationMessage,
    useUpdateConversation,
    useCreateConversation,
    useClearConversation,
    useUpdateConversationMessage,
    useAddConversationMessage,
    conversationsQueryOptions: queryOptions,
  } as const;
};
