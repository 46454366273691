import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { ApiAgent } from '@legalfly/api/agents';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { ApiDocument } from '@legalfly/api/documents';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import {
  getDocumentName,
  isDocumentFile,
  isDocumentFolder,
} from '@legalfly/components/documents/helpers';
import type { TrackingEvent } from '@legalfly/reporting/tracking';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { DialogClose, useDialog } from '@legalfly/ui/dialog';
import { withToasts } from '@legalfly/ui/toast';
import { useCreateAnonymization } from 'core/modules/anonymization';
import { useCreateConversation } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import {
  documentsToasts,
  useCountFolderDocuments,
  useCreateAnonymizedDocumentsZip,
  useCreateDocumentsZip,
  useDeleteDocuments,
  useDeleteFile,
  useDeleteFolder,
} from 'core/modules/documents';
import { useDownloadAnonymizedDocument, useDownloadDocument } from 'core/modules/documents/helpers';

import { MaxFilesDialog } from './dialogs/MaxFilesDialog';

const MAX_FILES_WARNING = 500;
const MAX_FILES_ERROR = 1000;

export const useDocumentActions = (documents: ApiDocument[]) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dialog = useDialog();

  const defaultJurisdiction = useDefaultJurisdiction();
  const { clearSelectedDocuments } = useSelectedDocuments();

  const { numberOfDocuments: numberOfSelectedDocumentsInFolders } = useCountFolderDocuments({
    uuids: documents.filter(isDocumentFolder).map((document) => document.uuid),
    enabled: documents.some(isDocumentFolder),
  });

  const { deleteFile, isLoading: isLoadingDeleteFile } = useDeleteFile({
    folderUuid: documents?.[0]?.parent?.uuid,
  });
  const { deleteFolder, isLoading: isLoadingDeleteFolder } = useDeleteFolder();
  const { deleteDocuments, isLoading: isLoadingDeleteDocuments } = useDeleteDocuments();
  const { createAnonymization, isLoading: isLoadingCreateAnonymization } = useCreateAnonymization();
  const {
    createConversation: createDiscoveryConversation,
    isLoading: isLoadingCreateConversation,
  } = useCreateConversation({
    type: ConversationTypeEnum.DISCOVERY,
  });
  const { createConversation: createAgentConversation } = useCreateConversation({
    type: ConversationTypeEnum.AGENT,
  });
  const { handleDownload: downloadAnonimized, isDownloading: isDownloadingAnonimized } =
    useDownloadAnonymizedDocument();
  const { handleDownload: downloadOriginal, isDownloading: isDownloadingOriginal } =
    useDownloadDocument();
  const { createDocumentsZip } = useCreateDocumentsZip();
  const { createAnonymizedDocumentsZip } = useCreateAnonymizedDocumentsZip();

  const isSingleFile = documents.length === 1 && isDocumentFile(documents[0]);
  const isSingleFolder = documents.length === 1 && isDocumentFolder(documents[0]);
  const isSingleSelection = isSingleFile || isSingleFolder;
  const files = documents.filter(isDocumentFile);
  const allowFolderActions = numberOfSelectedDocumentsInFolders > 0 || files.length > 0;
  const totalSelectedFiles = numberOfSelectedDocumentsInFolders + files.length;

  const handleMaxFiles = (onConfirm: () => void) => {
    if (totalSelectedFiles > MAX_FILES_WARNING) {
      trackEvent(
        {
          action: 'submit',
          category: 'documentsDetailPane',
          label: 'maxFiles',
        },
        { totalDocuments: totalSelectedFiles },
      );

      const isWarning = totalSelectedFiles < MAX_FILES_ERROR;
      const subTitle = isWarning
        ? t('dialog.maxFiles.warning', { count: MAX_FILES_WARNING })
        : t('dialog.maxFiles.error', { count: MAX_FILES_ERROR });

      dialog.open(
        <MaxFilesDialog subTitle={subTitle} type={isWarning ? 'warning' : 'error'}>
          {totalSelectedFiles < MAX_FILES_ERROR && (
            <DialogClose asChild>
              <Button variant='soft' onClick={onConfirm}>
                {t('action.start')}
              </Button>
            </DialogClose>
          )}
        </MaxFilesDialog>,
      );
      return;
    }

    onConfirm();
  };

  const handleAnonymisation = async () => {
    trackEvent(
      {
        action: 'click',
        category: 'documentsDetailPane',
        label: 'startAnonymisation',
      },
      { totalDocuments: totalSelectedFiles },
    );

    if (isSingleFile) {
      navigate({
        to: '/anonymisation/file/$uuid',
        params: { uuid: documents[0].uuid },
      });
      return;
    }

    const anonymization = await withToasts(
      createAnonymization({
        body: {
          documentUuids: documents.map((document) => document.uuid),
        },
      }),
    )(documentsToasts.createAnonymization());

    navigate({
      to: '/anonymisation/$uuid',
      params: { uuid: anonymization.uuid },
    });
  };

  const handleAgent = async (agentUuid: ApiAgent['uuid']) => {
    const conversation = await withToasts(
      createAgentConversation({
        jurisdiction: defaultJurisdiction,
        documentUuids: documents.map((document) => document.uuid),
        agentUuid,
      }),
    )(conversationToasts.createConversation());

    navigate({
      to: '/agents/$agentUuid/conversations/$conversationUuid',
      params: { agentUuid, conversationUuid: conversation.uuid },
    });
  };

  const handleDiscovery = async () => {
    trackEvent(
      {
        action: 'click',
        category: 'documentsDetailPane',
        label: 'startDiscovery',
      },
      { totalDocuments: totalSelectedFiles },
    );

    const conversation = await withToasts(
      createDiscoveryConversation({
        jurisdiction: defaultJurisdiction,
        documentUuids: documents.map((document) => document.uuid),
      }),
    )(conversationToasts.createConversation());

    navigate({
      to: '/discovery/$uuid',
      params: { uuid: conversation.uuid },
    });
  };

  const handleReview = async () => {
    navigate({
      to: '/review/new',
      search: {
        documentUuid: documents[0].uuid,
      },
    });
  };

  const handleMultiReview = async () => {
    trackEvent(
      {
        action: 'click',
        category: 'documentsDetailPane',
        label: 'startMultiReview',
      },
      { totalDocuments: totalSelectedFiles },
    );

    const folderUuid = documents[0].parent?.uuid;

    if (folderUuid) {
      navigate({
        to: '/multi-review/start/$uuid',
        params: { uuid: folderUuid },
        search: {
          documentUuids: documents.map((d) => d.uuid),
        },
        mask: {
          to: '/multi-review/start/$uuid',
          params: { uuid: folderUuid },
        },
      });
      return;
    }

    navigate({
      to: '/multi-review/start',
      search: {
        documentUuids: documents.map((d) => d.uuid),
      },
      mask: {
        to: '/multi-review/start',
      },
    });
  };

  const handleDeleteDocuments = async () => {
    trackEvent(
      {
        action: 'click',
        category: 'documentsDetailPane',
        label: 'deleteDocuments',
      },
      { totalDocuments: totalSelectedFiles },
    );

    const files = documents.filter(isDocumentFile);
    const folders = documents.filter(isDocumentFolder);
    let toast;
    if (isSingleFile || files.length) {
      toast = documentsToasts.deleteFile();
    } else if (isSingleFolder || folders.length) {
      toast = documentsToasts.deleteFolder();
    }

    withToasts(
      deleteDocuments({
        body: {
          fileUuids: files.map((d) => d.uuid),
          folderUuids: folders.map((d) => d.uuid),
        },
      }),
    )(toast);

    clearSelectedDocuments();
  };

  const handleDownloadOriginal = () => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'downloadOriginal',
    });

    downloadOriginal({
      uuid: documents[0].uuid,
      fileName: getDocumentName(documents[0]),
    });
  };

  const handleDownloadAnonimized = () => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'downloadAnonimised',
    });

    downloadAnonimized({
      uuid: documents[0].uuid,
      fileName: getDocumentName(documents[0]),
    });
  };

  const handleDeleteFile = async () => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'deleteFile',
    });
    await withToasts(deleteFile({ uuid: documents[0].uuid }))(documentsToasts.deleteFile());
    clearSelectedDocuments();
  };

  const handleDeleteFolder = async () => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'deleteFolder',
    });
    await withToasts(deleteFolder({ uuid: documents[0].uuid }))(documentsToasts.deleteFolder());
    clearSelectedDocuments();
  };

  const handleCreateDocumentsZip = async (event?: Partial<TrackingEvent>) => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'createZip',
      ...event,
    });

    await withToasts(
      createDocumentsZip({
        body: {
          uuids: documents.map((d) => d.uuid),
        },
      }),
    )(documentsToasts.createDocumentsZip());
  };

  const handleCreateAnonymizedDocumentsZip = async (event?: Partial<TrackingEvent>) => {
    trackEvent({
      action: 'click',
      category: 'documentsDetailPane',
      label: 'createAnonymizedZip',
      ...event,
    });

    await withToasts(
      createAnonymizedDocumentsZip({
        body: {
          uuids: documents.map((d) => d.uuid),
        },
      }),
    )(documentsToasts.createDocumentsZip());
  };

  return {
    handleAnonymisation: () => handleMaxFiles(handleAnonymisation),
    handleDiscovery: () => handleMaxFiles(handleDiscovery),
    handleMultiReview: () => handleMaxFiles(handleMultiReview),
    handleReview,
    handleDeleteDocuments,
    handleDownloadOriginal,
    handleDownloadAnonimized,
    handleDeleteFile,
    handleDeleteFolder,
    handleAgent,
    handleCreateDocumentsZip,
    handleCreateAnonymizedDocumentsZip,
    isLoadingCreateAnonymization,
    isLoadingCreateConversation,
    isDownloadingAnonimized,
    isDownloadingOriginal,
    isLoadingDeleteDocuments,
    isLoadingDeleteFile,
    isLoadingDeleteFolder,
    isSingleFile,
    isSingleFolder,
    isSingleSelection,
    allowFolderActions,
  };
};
