import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { legalFlyConfig, type LegalFlyLanguage } from '@legalfly/config';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';

interface Props {
  value: LegalFlyLanguage;
  onChange: (v: LegalFlyLanguage) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  contentProps?: ComponentProps<typeof SelectContent>;
  showI18nDummyKeys?: boolean;
}

const dummyLanguage = 'cimode';

export const SelectLanguage = ({
  value,
  onChange,
  triggerProps,
  contentProps,
  showI18nDummyKeys = false,
}: Props) => {
  const { t, i18n } = useTranslation('components');

  const languages = showI18nDummyKeys
    ? ([...legalFlyConfig.supportedLanguages, dummyLanguage] as const)
    : legalFlyConfig.supportedLanguages;

  return (
    <Select
      value={value}
      onValueChange={(t) => {
        if (t === dummyLanguage) {
          i18n.changeLanguage(t);
          return;
        }
        onChange(t as LegalFlyLanguage);
      }}
    >
      <SelectTrigger {...triggerProps}>
        <SelectValue placeholder='Select language'>{t(`language.${value}`)}</SelectValue>
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {languages.map((language) => (
          <SelectItem key={language} value={language}>
            {language === dummyLanguage ? 'Show keys' : t(`language.${language}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
