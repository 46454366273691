import type { CSSProperties, ForwardedRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, SortingState, TableOptions } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { fixedForwardRef } from '@legalfly/utils/refs';
import { cn } from 'utils';

import { DndDataTable } from './DndDataTable';
import { DraggableTableRow } from './DraggableTableRow';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import './styles.css';

const getPinningStyles = <TData,>(column: Column<TData>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize() === 0 ? undefined : column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
};

export interface TableDataRow {
  uuid: string;
  name: string;
}

interface Props<TData extends TableDataRow> extends Omit<TableOptions<TData>, 'getCoreRowModel'> {
  tableHeight: number;
  initialSorting?: SortingState;
  onRowClick?: (rowData: TData) => void;
  isDroppable: (rowData: TData) => boolean;
  onDropDocuments?: (p: { sourceUuids: string[]; targetUuid: string }) => void;
}

export const DataTable = fixedForwardRef(
  <TData extends TableDataRow>(
    {
      columns,
      data,
      onRowSelectionChange,
      isDroppable,
      onDropDocuments,
      tableHeight,
      initialSorting = [],
      ...props
    }: Props<TData>,
    ref: ForwardedRef<HTMLTableElement>,
  ) => {
    const { t } = useTranslation();
    const [sorting, setSorting] = useState<SortingState>(initialSorting);

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      onRowSelectionChange,
      ...props,
      state: {
        sorting,
        ...props.state,
      },
      defaultColumn: {
        minSize: 0,
        size: 0,
        enableResizing: false,
        ...props.defaultColumn,
      },
    });

    const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);

    const isDndEnabled = Boolean(onDropDocuments);
    const Row = isDndEnabled ? DraggableTableRow : TableRow;

    return (
      <DndDataTable
        onDropDocuments={onDropDocuments}
        rows={table.getRowModel().rows.map((r) => r.original)}
        selectedRows={selectedRows}
      >
        <Table
          parentClassName='table-container h-full'
          className='table-fixed'
          ref={ref}
          style={{ maxHeight: tableHeight }}
        >
          <TableHeader className='sticky top-0 z-10 bg-fill-maximal'>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='shadow-table-border'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn('text-content-body-weak', header.id === 'select' && 'px-0')}
                      style={{ ...getPinningStyles(header.column) }}
                    >
                      <div className='whitespace-nowrap'>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      {header.column.getCanResize() && (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                          onDoubleClick={() => header.column.resetSize()}
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        />
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Row
                  key={row.id}
                  id={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn(row.getIsSelected() && 'bg-fill-pressed-weak')}
                  {...(isDndEnabled && { isDroppable: isDroppable(row.original) })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                        padding: 0,
                        ...getPinningStyles(cell.column),
                      }}
                      className={cn(cell.column.id === 'select' && 'px-0')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </Row>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  {t('label.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DndDataTable>
    );
  },
);
